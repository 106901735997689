

button:not(:disabled) {
    outline: none;
}

.shadow {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.shadow-lg {
    -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
    box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.shadow-md {
    -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
    box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.badge-primary {
    background-color: $primary !important;
    color: #ffffff !important;
}

.badge-outline-primary {
    background-color: transparent !important;
    color: $primary !important;
    border: 1px solid $primary !important;
}

.badge-secondary {
    background-color: $secondary !important;
    color: #ffffff !important;
}

.badge-outline-secondary {
    background-color: transparent !important;
    color: $secondary !important;
    border: 1px solid $secondary !important;
}

.badge-success {
    background-color: $success !important;
    color: #ffffff !important;
}

.badge-outline-success {
    background-color: transparent !important;
    color: $success !important;
    border: 1px solid $success !important;
}

.badge-warning {
    background-color: $warning !important;
    color: #ffffff !important;
}

.badge-outline-warning {
    background-color: transparent !important;
    color: $warning !important;
    border: 1px solid $warning !important;
}

.badge-info {
    background-color: $info !important;
    color: #ffffff !important;
}

.badge-outline-info {
    background-color: transparent !important;
    color: $info !important;
    border: 1px solid $info !important;
}

.badge-danger {
    background-color: $danger !important;
    color: #ffffff !important;
}

.badge-outline-danger {
    background-color: transparent !important;
    color: $danger !important;
    border: 1px solid $danger !important;
}

.badge-dark {
    background-color: #58595B !important;
    color: #ffffff !important;
}

.badge-outline-dark {
    background-color: transparent !important;
    color: #58595B !important;
    border: 1px solid #58595B !important;
}

.badge-muted {
    background-color: #8492a6 !important;
    color: #ffffff !important;
}

.badge-outline-muted {
    background-color: transparent !important;
    color: #8492a6 !important;
    border: 1px solid #8492a6 !important;
}

.badge-light {
    background-color: #f8f9fc !important;
    color: #ffffff !important;
}

.badge-outline-light {
    background-color: transparent !important;
    color: #f8f9fc !important;
    border: 1px solid #f8f9fc !important;
}

.badge-blue {
    background-color: $primary !important;
    color: #ffffff !important;
}

.badge-outline-blue {
    background-color: transparent !important;
    color: $primary !important;
    border: 1px solid $primary !important;
}

.badge-footer {
    background-color: #202942 !important;
    color: #ffffff !important;
}

.badge-outline-footer {
    background-color: transparent !important;
    color: #202942 !important;
    border: 1px solid #202942 !important;
}

.badge {
    padding: 5px 8px;
    border-radius: 3px;
    letter-spacing: 0.5px;
}

.badge.badge-light {
    color: #58595B !important;
    background-color: #f8f9fc !important;
}

.badge.badge-outline-light {
    color: #58595B !important;
    border: 1px solid #b1bcdd !important;
    background-color: transparent !important;
}

.badge.badge-pill {
    border-radius: 12px;
}

.badge:focus,
.badge.focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.dropdown-primary .dropdown-menu .dropdown-item:hover,
.dropdown-primary .dropdown-menu .dropdown-item.active,
.dropdown-primary .dropdown-menu .dropdown-item:active,
.dropdown-primary .dropdown-menu .dropdown-item.focus,
.dropdown-primary .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: $primary !important;
}

.dropdown-secondary .dropdown-menu .dropdown-item:hover,
.dropdown-secondary .dropdown-menu .dropdown-item.active,
.dropdown-secondary .dropdown-menu .dropdown-item:active,
.dropdown-secondary .dropdown-menu .dropdown-item.focus,
.dropdown-secondary .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: $secondary !important;
}

.dropdown-success .dropdown-menu .dropdown-item:hover,
.dropdown-success .dropdown-menu .dropdown-item.active,
.dropdown-success .dropdown-menu .dropdown-item:active,
.dropdown-success .dropdown-menu .dropdown-item.focus,
.dropdown-success .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: $success !important;
}

.dropdown-warning .dropdown-menu .dropdown-item:hover,
.dropdown-warning .dropdown-menu .dropdown-item.active,
.dropdown-warning .dropdown-menu .dropdown-item:active,
.dropdown-warning .dropdown-menu .dropdown-item.focus,
.dropdown-warning .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: $warning !important;
}

.dropdown-info .dropdown-menu .dropdown-item:hover,
.dropdown-info .dropdown-menu .dropdown-item.active,
.dropdown-info .dropdown-menu .dropdown-item:active,
.dropdown-info .dropdown-menu .dropdown-item.focus,
.dropdown-info .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: $info !important;
}

.dropdown-danger .dropdown-menu .dropdown-item:hover,
.dropdown-danger .dropdown-menu .dropdown-item.active,
.dropdown-danger .dropdown-menu .dropdown-item:active,
.dropdown-danger .dropdown-menu .dropdown-item.focus,
.dropdown-danger .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: $danger !important;
}

.dropdown-dark .dropdown-menu .dropdown-item:hover,
.dropdown-dark .dropdown-menu .dropdown-item.active,
.dropdown-dark .dropdown-menu .dropdown-item:active,
.dropdown-dark .dropdown-menu .dropdown-item.focus,
.dropdown-dark .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #58595B !important;
}

.dropdown-muted .dropdown-menu .dropdown-item:hover,
.dropdown-muted .dropdown-menu .dropdown-item.active,
.dropdown-muted .dropdown-menu .dropdown-item:active,
.dropdown-muted .dropdown-menu .dropdown-item.focus,
.dropdown-muted .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #8492a6 !important;
}

.dropdown-light .dropdown-menu .dropdown-item:hover,
.dropdown-light .dropdown-menu .dropdown-item.active,
.dropdown-light .dropdown-menu .dropdown-item:active,
.dropdown-light .dropdown-menu .dropdown-item.focus,
.dropdown-light .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #f8f9fc !important;
}

.dropdown-blue .dropdown-menu .dropdown-item:hover,
.dropdown-blue .dropdown-menu .dropdown-item.active,
.dropdown-blue .dropdown-menu .dropdown-item:active,
.dropdown-blue .dropdown-menu .dropdown-item.focus,
.dropdown-blue .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: $primary !important;
}

.dropdown-footer .dropdown-menu .dropdown-item:hover,
.dropdown-footer .dropdown-menu .dropdown-item.active,
.dropdown-footer .dropdown-menu .dropdown-item:active,
.dropdown-footer .dropdown-menu .dropdown-item.focus,
.dropdown-footer .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #202942 !important;
}

.btn-group .dropdown-toggle:after {
    content: "";
    position: relative;
    right: -4px;
    top: -2px;
    border: solid #ffffff;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 0;
    vertical-align: 0;
}

.btn-group .dropdown-menu {
    margin-top: 10px;
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.btn-group .dropdown-menu:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 7px solid #495057;
    border-radius: 0.5px;
    border-color: transparent transparent #ffffff #ffffff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
    box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
    right: 10px !important;
}

.alert-black {
  background: #455a64 !important;
}

.alert-primary {
    background-color: $primary;
    color: #ffffff;
    border-color: $primary;
}

.alert-primary .alert-link {
    color: #122258;
}

.alert-outline-primary {
    background-color: #ffffff;
    color: $primary;
    border-color: $primary;
}

.alert-secondary {
    background-color: rgba(90, 109, 144, 0.9);
    color: #ffffff;
    border-color: $secondary;
}

.alert-secondary .alert-link {
    color: #1f2632;
}

.alert-outline-secondary {
    background-color: #ffffff;
    color: $secondary;
    border-color: $secondary;
}

.alert-success {
    background-color: rgba(46, 202, 139, 0.9);
    color: #ffffff;
    border-color: $success;
}

.alert-success .alert-link {
    color: #124d35;
}

.alert-outline-success {
    background-color: #ffffff;
    color: $success;
    border-color: $success;
}

.alert-warning {
    background-color: rgba(241, 116, 37, 0.9);
    color: #ffffff;
    border-color: $warning;
}

.alert-warning .alert-link {
    color: #753208;
}

.alert-outline-warning {
    background-color: #ffffff;
    color: $warning;
    border-color: $warning;
}

.alert-info {
    background-color: rgba(23, 162, 184, 0.9);
    color: #ffffff;
    border-color: $info;
}

.alert-info .alert-link {
    color: #062a30;
}

.alert-outline-info {
    background-color: #ffffff;
    color: $info;
    border-color: $info;
}

.alert-danger {
    background-color: rgba(228, 63, 82, 0.9);
    color: #ffffff;
    border-color: $danger;
}

.alert-danger .alert-link {
    color: #79111d;
}

.alert-outline-danger {
    background-color: #ffffff;
    color: $danger;
    border-color: $danger;
}

.alert-dark {
    background-color: rgba(60, 72, 88, 0.9);
    color: #ffffff;
    border-color: #58595B;
}

.alert-dark .alert-link {
    color: black;
}

.alert-outline-dark {
    background-color: #ffffff;
    color: #58595B;
    border-color: #58595B;
}

.alert-muted {
    background-color: rgba(132, 146, 166, 0.9);
    color: #ffffff;
    border-color: #8492a6;
}

.alert-muted .alert-link {
    color: #3d4654;
}

.alert-outline-muted {
    background-color: #ffffff;
    color: #8492a6;
    border-color: #8492a6;
}

.alert-light {
    background-color: rgba(248, 249, 252, 0.9);
    color: #ffffff;
    border-color: #f8f9fc;
}

.alert-light .alert-link {
    color: #8d9dce;
}

.alert-outline-light {
    background-color: #ffffff;
    color: #f8f9fc;
    border-color: #f8f9fc;
}

.alert-blue {
    background-color: rgba(47, 85, 212, 0.9);
    color: #ffffff;
    border-color: $primary;
}

.alert-blue .alert-link {
    color: #122258;
}

.alert-outline-blue {
    background-color: #ffffff;
    color: $primary;
    border-color: $primary;
}

.alert-footer {
    background-color: rgba(32, 41, 66, 0.9);
    color: #ffffff;
    border-color: #202942;
}

.alert-footer .alert-link {
    color: black;
}

.alert-outline-footer {
    background-color: #ffffff;
    color: #202942;
    border-color: #202942;
}

.alert {
    padding: 8px 15px;
    border-radius: 6px;
    font-size: 14px;
}

.alert.alert-light {
    background-color: #f8f9fc;
    color: #58595B;
    border-color: #dee2e6;
}

.alert.alert-dismissible .close {
    top: -2px;
    right: -10px;
    font-size: 16px !important;
}

.alert.alert-pills {
    border-radius: 30px;
    display: inline-block;
}

.alert.alert-pills .content {
    font-weight: 600;
}

.page-next-level .title {
    font-size: 28px;
    letter-spacing: 1px;
}

.page-next-level .page-next {
    position: relative;
    top: 110px;
    z-index: 99;
}

.breadcrumb {
    letter-spacing: 0.5px;
    padding: 8px 24px;
    display: block;
}

.breadcrumb .breadcrumb-item a {
    color: #58595B;
}

.breadcrumb .breadcrumb-item a:hover {
    color: $primary;
}

.breadcrumb .breadcrumb-item.active {
    color: $primary;
}

.breadcrumb .breadcrumb-item:last-child:after {
    display: none;
}

.pagination .page-item .page-link {
    color: #58595B;
    border: 1px solid #dee2e6;
    padding: 10px 15px;
    font-size: 14px;
}

.pagination .page-item .page-link:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pagination .page-item .page-link:hover {
    color: #ffffff;
    background: rgba(47, 85, 212, 0.9);
    border-color: rgba(47, 85, 212, 0.9);
}

.pagination .page-item.active .page-link {
    color: #ffffff;
    background: $primary !important;
    border-color: $primary;
    cursor: not-allowed;
}

.avatar.avatar-ex-sm {
    max-height: 25px;
}

.avatar.avatar-md-sm {
    height: 45px;
    width: 45px;
}

.avatar.avatar-small {
    height: 65px;
    width: 65px;
}

.avatar.avatar-md-md {
    height: 80px;
    width: 80px;
}

.avatar.avatar-medium {
    height: 110px;
    width: 110px;
}

.avatar.avatar-large {
    height: 140px;
    width: 140px;
}

.avatar.avatar-ex-large {
    height: 180px;
    width: 180px;
}

.faq-content .card a.faq[data-toggle=collapse] {
    background-color: #ffffff;
    color: $primary;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.faq-content .card a.faq[data-toggle=collapse].collapsed {
    background-color: #ffffff;
    color: #58595B !important;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.faq-content .card a.faq[data-toggle=collapse].collapsed:before {
    top: 15px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    color: #58595B !important;
}

.faq-content .card .title {
    font-size: 16px;
}

.progress-box .title {
    font-size: 15px;
}

.progress-box .progress {
    height: 8px;
    overflow: visible;
}

.progress-box .progress .progress-bar {
    border-radius: 6px;
    -webkit-animation: animate-positive 3s;
    animation: animate-positive 3s;
    overflow: visible !important;
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
}

.form-group label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0.25rem;
}

.form-group .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    color: #58595B;
    height: 42px;
    font-size: 13px;
    border-radius: 6px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.form-group .form-control:focus,
.form-group .form-control.active {
    border-color: $primary;
}

.form-group textarea {
    height: 120px !important;
}

.form-group textarea.form-control {
    line-height: 25px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}

.custom-control-input:checked ~ .custom-control-label:before {
    color: #ffffff;
    border-color: $primary;
    background-color: $primary;
}

.custom-control-input:focus ~ .custom-control-label::before,
.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: $primary;
}

.custom-control-label {
    cursor: pointer;
}

.subcribe-form form {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive .table td,
.table-responsive .table th {
    vertical-align: middle;
}

.table-responsive .table-center th {
    vertical-align: middle !important;
}

.table-responsive .table-center tbody tr:hover {
    color: #58595B;
    background-color: #f8f9fa;
}

.table-responsive .table-center.invoice-tb th,
.table-responsive .table-center.invoice-tb td {
    text-align: end;
}

.p-datatable>.p-datatable-wrapper {
    overflow: auto;
    border: 1px solid #e9ecef;
    border-width: 0 1px 0 1px;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
    z-index: 9999999;
}

#preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#preloader #status .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

#preloader #status .spinner .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.social-icon li a {
    color: #58595B;
    border: 1px solid #58595B;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 28px;
    text-align: center;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
}

.social-icon li a .fea-social {
    stroke-width: 2;
}

.social-icon li a:hover {
    background-color: $primary;
    border-color: $primary !important;
    color: #ffffff !important;
}

.social-icon li a:hover .fea-social {
    fill: $primary;
}

.social-icon.social li a {
    color: #adb5bd;
    border-color: #adb5bd;
}

.back-to-top .icons {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.back-to-top:hover {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.back-to-top:hover .icons {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sticky-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
}

.fea {
    stroke-width: 1.8;
}

.fea.icon-sm {
    height: 16px;
    width: 16px;
}

.fea.icon-ex-md {
    height: 20px;
    width: 20px;
}

.fea.icon-m-md {
    height: 28px;
    width: 28px;
}

.fea.icon-md {
    height: 35px;
    width: 35px;
}

.fea.icon-lg {
    height: 42px;
    width: 42px;
}

.fea.icon-ex-lg {
    height: 52px;
    width: 52px;
}

.display-1 {
    font-size: 80px !important;
}

.display-2 {
    font-size: 72px !important;
}

.display-3 {
    font-size: 64px !important;
}

.display-4 {
    font-size: 56px !important;
}

[class^=uil-]:before,
[class*=" uil-"]:before {
    margin: 0;
}

.uim-svg {
    vertical-align: 0 !important;
}

@media (max-width: 768px) {
    .page-next-level .page-next {
        top: 70px;
    }

    .display-1 {
        font-size: 56px !important;
    }

    .display-2 {
        font-size: 50px !important;
    }

    .display-3 {
        font-size: 42px !important;
    }

    .display-4 {
        font-size: 36px !important;
    }
}

body {
    font-family: 'Almarai', sans-serif;
    overflow-x: hidden !important;
    font-size: 16px;
    color: #161c2d;
    text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Almarai", sans-serif;
    line-height: 1.5;
    font-weight: 600;
}

a {
    text-decoration: none !important;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

div.p-tabview-nav-content li:not(.p-highlight) a {
        color: inherit !important;
}

p {
    line-height: 1.6;
}

.section {
    padding: 100px 0;
    position: relative;
}

.section-two {
    padding: 60px 0;
    position: relative;
}

.bg-overlay {
    background-color: rgba(60, 72, 88, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.bg-overlay-gradient {
    background-color: rgba(47, 85, 212, 0.6);
}

.bg-overlay-white {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-overlay-gradient2 {
    background-image: -webkit-gradient(linear, left top, right top, from($success), color-stop($primary), to($success));
    background-image: linear-gradient(90deg, $success, $primary, $success);
    opacity: 0.5;
}

.title-heading {
    line-height: 26px;
}

.title-heading .heading {
    font-size: 45px;
    letter-spacing: 1px;
}

.title-heading .para-desc {
    font-size: 18px;
}

.section-title {
    position: relative;
}

.section-title .title {
    font-size: 36px;
    font-weight: 700;
}

.text-shadow-title {
    text-shadow: 2px 0 0 #ffffff, -2px 0 0 #ffffff, 0 4px 0 rgba(255, 255, 255, 0.4), 0 -2px 0 #ffffff, 1px 1px #ffffff, -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff;
}

.para-desc {
    max-width: 600px;
}

.mt-100 {
    margin-top: 100px;
}

.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
}

.shape > svg {
    -webkit-transform: scale(2);
    transform: scale(2);
    width: 100%;
    height: auto;
    -webkit-transform-origin: top center;
    transform-origin: top center;
}

.mover {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

.feature-posts-placeholder {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 66.6%;
}

.spin-anything {
    -webkit-animation: spinner-animation 5s linear infinite;
    animation: spinner-animation 5s linear infinite;
}

@-webkit-keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
    }

    .margin-top-100 .heading {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .section {
        padding: 60px 0;
    }

    .title-heading .heading {
        font-size: 35px !important;
    }

    .title-heading .para-desc {
        font-size: 15px;
    }

    .section-title .title {
        font-size: 24px;
    }

    .mt-60 {
        margin-top: 60px;
    }
}

@media (max-width: 425px) {
    .shape {
        bottom: -4px;
    }
}

@media (max-width: 425px) {
    .feature-posts-placeholder {
        height: 80%;
    }
}

#topnav {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    background-color: transparent;
    border: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

#topnav .logo .l-dark {
    display: none;
}

#topnav .logo .l-light {
    display: inline-block;
}

#topnav .has-submenu.active a {
    color: #ffffff;
}

#topnav .has-submenu.active .submenu li.active > a {
    color: $primary !important;
}

#topnav .has-submenu.active.active .menu-arrow {
    border-color: $primary;
}

#topnav .has-submenu {
    position: relative;
}

#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow {
    border-color: $primary;
}

#topnav .navbar-toggle {
    background: none;
    border: 0;
    position: relative;
    width: 60px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

#topnav .navbar-toggle .lines {
    width: 25px;
    display: block;
    position: relative;
    margin: 28px 20px;
    height: 18px;
}

#topnav .navbar-toggle span {
    height: 2px;
    width: 100%;
    background-color: #58595B;
    display: block;
    margin-bottom: 5px;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}

#topnav .navbar-toggle span:last-child {
    margin-bottom: 0;
}

#topnav .buy-button .login-btn-primary {
    display: none;
}

#topnav .buy-button .login-btn-light {
    display: inline-block;
}

#topnav .navbar-toggle.open span {
    position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
    visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
    width: 100%;
    top: 6px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
    background-color: $primary;
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus {
    background-color: transparent;
}

#topnav .navigation-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
}

#topnav .navigation-menu > li {
    float: left;
    display: block;
    position: relative;
    margin: 0 10px;
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a.active {
    color: $primary !important;
}

#topnav .navigation-menu > li > a.active span {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid;
}

#topnav .navigation-menu > li > a {
    display: block;
    color: #58595B;
    font-size: 18px;
    background-color: transparent !important;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-family: "Almarai", sans-serif;
    padding-left: 15px;
    padding-right: 15px;
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
    color: $primary;
}

#topnav .navigation-menu .has-submenu:hover .menu-arrow {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

#topnav .menu-extras {
    float: right;
}

#topnav.scroll {
    background-color: #ffffff;
    border: none;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.scroll .navigation-menu > li > a {
    color: #58595B;
}

#topnav.scroll .navigation-menu > li > .menu-arrow {
    border-color: #58595B;
}

#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
    color: $primary;
}

#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
    border-color: $primary;
}

#topnav.defaultscroll.dark-menubar .logo {
    line-height: 70px;
}

#topnav.defaultscroll.scroll .logo {
    line-height: 62px;
}

#topnav.defaultscroll.scroll.dark-menubar .logo {
    line-height: 62px;
}

#topnav.nav-sticky {
    background: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.nav-sticky .navigation-menu.nav-light > li > a {
    color: #58595B;
}

#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: $primary !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
    border-color: $primary !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: $primary !important;
}

#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: #58595B;
}

#topnav.nav-sticky .buy-button .login-btn-primary {
    display: inline-block;
}

#topnav.nav-sticky .buy-button .login-btn-light {
    display: none;
}

#topnav.nav-sticky .logo .l-dark {
    display: inline-block;
}

#topnav.nav-sticky .logo .l-light {
    display: none;
}

.logo {
    font-weight: 700;
    font-size: 24px;
    padding: 0 0 6px;
    letter-spacing: 1px;
    line-height: 68px;
}

@media (min-width: 992px) {
    #topnav .navigation-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    }

    #topnav .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu::before {
        top: 20px;
        margin-left: -35px;
        -webkit-box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
        box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
        border-color: transparent #ffffff #ffffff transparent;
    }

    #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
        top: 33px !important;
    }

    .has-submenu:hover .menu-arrow {
        top: 33px !important;
    }

    #topnav .navigation-menu > .has-submenu.active .menu-arrow {
        top: 30px;
    }

    .has-submenu.active .menu-arrow {
        top: 30px;
    }

    #topnav .navigation-menu > li .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        padding: 15px 0;
        list-style: none;
        min-width: 180px;
        visibility: hidden;
        opacity: 0;
        margin-top: 10px;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        border-radius: 6px;
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    }

    #topnav .navigation-menu > li .submenu:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 45px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 7px solid #495057;
        border-color: transparent transparent #ffffff #ffffff;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        -webkit-box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
        box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
    }

    #topnav .navigation-menu > li .submenu li {
        position: relative;
    }

    #topnav .navigation-menu > li .submenu li a {
        display: block;
        padding: 10px 20px;
        clear: both;
        white-space: nowrap;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #58595B !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    #topnav .navigation-menu > li .submenu li a:hover {
        color: $primary !important;
    }

    #topnav .navigation-menu > li .submenu li ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }

    #topnav .navigation-menu > li .submenu.megamenu {
        white-space: nowrap;
        width: auto;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li {
        overflow: hidden;
        min-width: 180px;
        display: inline-block;
        vertical-align: top;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
        left: 100%;
        top: 0;
        margin-left: 10px;
        margin-top: -1px;
    }

    #topnav .navigation-menu > li .submenu > li .submenu {
        left: 106%;
        top: 0;
        margin-left: 10px;
        margin-top: -1px;
    }

    #topnav .navigation-menu > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
        min-height: 62px;
    }

    #topnav .navigation-menu > li:hover > .menu-arrow {
        border-color: $primary;
    }

    #topnav .navigation-menu > li:hover > a,
    #topnav .navigation-menu > li.active > a {
        color: $primary !important;
    }

    #topnav .navigation-menu > li.last-elements .submenu {
        left: auto;
        right: 0;
    }

    #topnav .navigation-menu > li.last-elements .submenu:before {
        left: auto;
        right: 10px;
    }

    #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: 10px;
    }

    #topnav .navigation-menu.nav-light > li > a {
        color: rgba(255, 255, 255, 0.5);
    }

    #topnav .navigation-menu.nav-light > li.active > a {
        color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
        border-color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-light > li:hover > a {
        color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
        border-color: rgba(255, 255, 255, 0.5);
    }

    #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
        border-color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-right {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    #topnav .navigation-menu.nav-left {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu {
        left: 0 !important;
        right: auto !important;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
        left: 45px !important;
        right: auto !important;
    }

    #topnav .buy-button {
        padding-left: 15px;
        margin-left: 15px;
    }

    #topnav .navbar-toggle {
        display: none;
    }

    #topnav #navigation {
        display: block !important;
    }

    #topnav.scroll {
        top: 0;
    }

    #topnav.scroll .navigation-menu > li > a {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #topnav.scroll-active .navigation-menu > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

@media (max-width: 991px) {
    #topnav {
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        min-height: 74px;
    }

    #topnav .logo .l-dark {
        display: inline-block !important;
    }

    #topnav .logo .l-light {
        display: none !important;
    }

    #topnav .container {
        width: auto;
    }

    #topnav #navigation {
        max-height: 400px;
    }

    #topnav .navigation-menu {
        float: none;
    }

    #topnav .navigation-menu > li {
        float: none;
    }

    #topnav .navigation-menu > li .submenu {
        display: none;
        list-style: none;
        padding-left: 20px;
        margin: 0;
    }

    #topnav .navigation-menu > li .submenu li a {
        display: block;
        position: relative;
        padding: 7px 15px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #58595B !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    #topnav .navigation-menu > li .submenu.open {
        display: block;
    }

    #topnav .navigation-menu > li .submenu .submenu {
        display: none;
        list-style: none;
    }

    #topnav .navigation-menu > li .submenu .submenu.open {
        display: block;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li > ul {
        list-style: none;
        padding-left: 0;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
        display: block;
        position: relative;
        padding: 10px 15px;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        color: #8492a6;
    }

    #topnav .navigation-menu > li > a {
        color: #58595B;
        padding: 10px 20px;
    }

    #topnav .navigation-menu > li > a:after {
        position: absolute;
        right: 15px;
    }

    #topnav .navigation-menu > li > a:hover,
    #topnav .navigation-menu > li .submenu li a:hover,
    #topnav .navigation-menu > li.has-submenu.open > a {
        color: $primary;
    }

    #topnav .menu-extras .menu-item {
        border-color: #8492a6;
    }

    #topnav .navbar-header {
        float: left;
    }

    #topnav .buy-button .login-btn-primary {
        display: inline-block !important;
    }

    #topnav .buy-button .login-btn-light {
        display: none;
    }

    #topnav .has-submenu .submenu .submenu-arrow {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: 12px;
    }

    #topnav .has-submenu.active a {
        color: $primary;
    }

    #navigation {
        position: absolute;
        top: 74px;
        left: 0;
        width: 100%;
        display: none;
        height: auto;
        padding-bottom: 0;
        overflow: auto;
        border-top: 1px solid #f1f3f9;
        border-bottom: 1px solid #f1f3f9;
        background-color: #ffffff;
    }

    #navigation.open {
        display: block;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {
    #topnav .navigation-menu .has-submenu .menu-arrow {
        right: 16px;
        top: 16px;
    }

    .has-submenu .menu-arrow {
        right: 16px;
        top: 16px;
    }

    #topnav .buy-button {
        padding: 3px 0;
    }
}

@media (min-width: 768px) {
    #topnav .navigation-menu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
    }

    #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-toggle {
        display: block;
    }
}

@media (max-width: 441px) {
    #topnav .buy-button {
        display: none;
    }

    #topnav .menu-extras {
      float: left;
    }

    #topnav .buy-menu-btn {
        display: block !important;
        margin: 0 10px;
        padding: 10px 20px;
    }

    #topnav .navbar-toggle .lines {
        margin-right: 0 !important;
    }
}

.bg-home {
    height: 100vh;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-half-260 {
    padding: 260px 0;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-half-120 {
    padding: 120px 0;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-half {
    padding: 184px 0 90px;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.gradient {
    background: $primary !important;
    background: linear-gradient(45deg, $primary, $primary) !important;
    opacity: 0.9;
}

.bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba(47, 85, 212, 0.1) 0%, rgba(47, 85, 212, 0.1) 33.333%, rgba(47, 85, 212, 0.3) 33.333%, rgba(47, 85, 212, 0.3) 66.666%, rgba(47, 85, 212, 0.5) 66.666%, rgba(47, 85, 212, 0.5) 99.999%);
}

.home-dashboard img {
    position: relative;
    top: 60px;
    z-index: 1;
}

.studio-home {
    padding: 7%;
}

.bg-marketing {
    padding: 76px 0 120px;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.home-slider .carousel-control-next,
.home-slider .carousel-control-prev {
    width: 5%;
}

.home-slider .carousel-item,
.home-slider .carousel {
    height: 97vh;
    width: 100%;
}

.home-slider .carousel-item {
    background-position: center center;
    background-size: cover;
}

.home-slider .carousel-item.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.main-slider .slides .bg-slider {
    height: 97vh;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.main-slider .slides .bg-slider.shopping {
    height: 75vh;
}

.flex-control-paging li a {
    background: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.flex-control-paging li a.flex-active {
    background: $primary;
    cursor: default;
    font-size: 17px;
    width: 15px;
    height: 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.flex-control-nav {
    display: none;
}

.bg-animation-left.dark-left:after {
    background: rgba(60, 72, 88, 0.6);
}

.bg-invoice {
    padding: 100px 0;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.swiper-slider-hero .swiper-container,
.swiper-slider-hero .swiper-slide .slide-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.swiper-slider-hero .swiper-container .swiper-button-prev,
.swiper-slider-hero .swiper-container .swiper-button-next {
    background: transparent;
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-top: -30px;
}

.swiper-slider-hero .swiper-container .swiper-button-prev:before,
.swiper-slider-hero .swiper-container .swiper-button-next:before {
    font-weight: 900;
    color: #ffffff;
}

.swiper-slider-hero .swiper-container .swiper-button-prev:hover,
.swiper-slider-hero .swiper-container .swiper-button-next:hover {
    background: $primary;
    border-color: $primary !important;
}

.swiper-slider-hero .swiper-container .swiper-button-prev {
    left: 10px;
}

.swiper-slider-hero .swiper-container .swiper-button-prev:before {
    content: "\f0141";
}

.swiper-slider-hero .swiper-container .swiper-button-next {
    right: 10px;
}

.swiper-slider-hero .swiper-container .swiper-button-next:before {
    content: "\f0142";
}

.swiper-slider-hero .swiper-pagination-bullet {
    color: #f8f9fa;
    background: transparent;
}

.swiper-slider-hero .swiper-pagination-bullet-active {
    color: #ffffff;
}

.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-slider-hero .swiper-pagination-custom,
.swiper-slider-hero .swiper-pagination-fraction {
    bottom: 45px;
}

.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 13px;
}

@media (min-width: 769px) {
    .bg-studio:after {
        content: "";
        position: absolute;
        right: 0px;
        top: 0px;
        width: 50%;
        height: 100%;
        background: #ffffff none repeat scroll 0 0;
        z-index: 2;
    }
}

@media (min-width: 768px) {
    .home-dashboard.onepage-hero {
        overflow: hidden;
    }
}

@media (max-width: 768px) {
    .agency-wrapper:before {
        right: 12rem;
    }

    .bg-half {
        padding: 169px 0 50px;
    }

    .wave-one,
    .wave-three {
        background-size: 50% 50px;
    }

    .wave-two {
        background-size: 50% 70px;
    }

    .classic-saas-image .bg-saas-shape img {
        max-width: 550px;
    }
}

@media (max-width: 767px) {

    .bg-home,
    .bg-half-120,
    .bg-half-260,
    .main-slider .slides .bg-slider,
    .bg-marketing,
    .swiper-slider-hero .swiper-container .swiper-slide {
        padding: 150px 0;
        height: auto;
    }

    .agency-wrapper:before {
        right: 0;
    }

    .bg-animation-left:after {
        width: 100%;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    .classic-saas-image .bg-saas-shape img {
        max-width: 100%;
        height: auto;
    }

    .classic-saas-image .bg-saas-shape:after {
        bottom: -5rem;
        left: 10rem;
        width: 30rem;
        height: 30rem;
        border-radius: 10rem;
    }
}

.features .icon,
.features .icons {
    background: rgba(47, 85, 212, 0.1);
}

.features .icons {
    height: 80px;
    line-height: 80px;
    margin: 0 auto;
}

.features .image:before {
    content: "";
    position: absolute;
    bottom: 5px;
    right: -15px;
    width: 70px;
    height: 70px;
    border-radius: 6px;
    -webkit-transform: rotate(33.75deg);
    transform: rotate(33.75deg);
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.09));
    z-index: -1;
    left: -15px;
}

.features .ride-image {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.features .ride-image img {
    border-radius: 100% 100% 100% 0;
}

.features .title-2 {
    font-size: 20px;
}

.features:hover .image:before {
    background: rgba(47, 85, 212, 0.07);
    -webkit-animation: spinner-border 5s linear infinite;
    animation: spinner-border 5s linear infinite;
}

.features.fea-primary {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.features.fea-primary .content .title {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.features.fea-primary .content .title:hover {
    color: $primary !important;
}

.features.fea-primary .big-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    opacity: 0.015;
    font-size: 180px;
}

.features.fea-primary:hover {
    -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15);
    box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15);
    background-color: $primary !important;
    color: #ffffff !important;
}

.features.fea-primary:hover .icon2,
.features.fea-primary:hover .para {
    color: rgba(255, 255, 255, 0.5) !important;
}

.features.fea-primary:hover .content,
.features.fea-primary:hover .icon2 {
    z-index: 2;
}

.features.fea-primary:hover .big-icon {
    opacity: 0.05;
}

.features .title,
.key-feature .title {
    font-size: 18px;
}

.features .icon,
.key-feature .icon {
    height: 45px;
    width: 45px;
    line-height: 45px;
}

.key-feature {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.15));
}

.key-feature:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
    box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.work-process {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.work-process .title {
    font-size: 20px;
}

.work-process .step,
.work-process .step-icon {
    opacity: 0.06;
}

.work-process a {
    font-size: 15px;
}

.work-process.process-arrow:hover {
    -webkit-transform: none !important;
    transform: none !important;
}

.work-process:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.work-process.d-none-arrow:hover {
    -webkit-transform: none !important;
    transform: none !important;
}

.course-feature .title {
    font-size: 20px;
}

.course-feature .title:hover {
    color: $primary !important;
}

.course-feature .full-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: 0 auto;
    z-index: -2;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.course-feature:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.course-feature:hover .full-img {
    opacity: 0.08;
}

.crypto-table,
.course-feature,
.features-absolute {
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.crypto-table,
.course-feature {
    margin: -260px 0 0px;
}

.features-absolute {
    margin: -200px 0 0px;
}

.features-absolute.blog-search {
    margin: -120px 0 0px;
}

.catagories {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.catagories:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.catagories a:hover {
    color: $primary !important;
}

.app-feature-shape-left:after {
    right: -5rem;
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    bottom: 0rem;
    width: 30rem;
    height: 40rem;
    border-radius: 10rem;
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(47, 85, 212, 0.1);
    -webkit-box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
}

.app-subscribe {
    position: absolute;
    top: -200px;
}

.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(47, 85, 212, 0.1);
    -webkit-box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
}

.social-feature-left:after,
.social-feature-right:after {
    width: 450px;
    height: 520px;
    border-radius: 30px;
    bottom: -75px;
}

.social-feature-left:after {
    right: -200px;
}

.social-feature-right:after {
    left: -200px;
}

.explore-feature {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.explore-feature .icon {
    height: 80px;
    width: 80px;
    line-height: 80px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.explore-feature:hover {
    -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
    box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.explore-feature:hover .icon,
.explore-feature:hover .title {
    color: $primary !important;
}

@media (max-width: 768px) {
    .app-feature-shape-right:after {
        left: 0;
    }

    .app-feature-shape-left:after {
        right: 0;
    }

    .social-feature-left:after,
    .social-feature-right:after {
        width: 300px;
        height: 400px;
        bottom: -65px;
    }

    .features-absolute {
        margin: -140px 0 0;
    }

    .features-absolute.blog-search {
        margin: -90px 0 0px;
    }
}

@media screen and (max-width: 767px) {
    .process-arrow:after {
        display: none;
    }

    .course-feature,
    .crypto-table {
        margin: 0 0 30px;
    }

    .app-subscribe {
        position: relative;
        top: 0;
    }

    .saas-feature-shape-left:after,
    .saas-feature-shape-right:after {
        bottom: -1rem;
        width: 25rem;
        height: 25rem;
        border-radius: 10rem;
    }
}

@media (max-width: 575px) {

    .social-feature-left:after,
    .social-feature-right:after {
        bottom: -35px;
    }

    .social-feature-left:after {
        right: 60px;
    }

    .social-feature-right:after {
        left: 60px;
    }
}

.customer-testi {
    cursor: e-resize;
}

.customer-testi .content:before {
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    margin-left: 13px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 8px solid #58595B;
    border-color: transparent #ffffff #ffffff transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-box-shadow: 2px 2px 2px -1px rgba(60, 72, 88, 0.15);
    box-shadow: 2px 2px 2px -1px rgba(60, 72, 88, 0.15);
}

.owl-theme .owl-dots .owl-dot span {
    border-radius: 3px !important;
    background: rgba(47, 85, 212, 0.5) !important;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots.clickable .owl-dot:hover span {
    background: $primary !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.owl-carousel .owl-item img {
    display: block;
    width: auto;
}

.slick-slider {
    overflow-x: hidden !important;
    cursor: pointer;
}

.pricing-rates {
    position: relative;
    z-index: 1;
    border-bottom: 3px solid #adb5bd !important;
}

.pricing-rates .title {
    font-weight: 700;
    font-size: 18px;
}

.pricing-rates .price {
    font-weight: 400;
}

.pricing-rates.business-rate {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pricing-rates.business-rate:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    z-index: 2;
    border-color: $primary;
    -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
    box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
    background-color: #ffffff !important;
}

.pricing-rates.business-rate:hover .title {
    color: $primary;
}

.pricing-rates.starter-plan {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 2;
    border-color: $primary;
}

.pricing-rates.best-plan {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.pricing-rates.best-plan .lable h6 {
    font-size: 12px;
}

.pricing-rates.best-plan:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.invoice-top .logo-invoice {
    font-weight: 700;
    font-size: 27px;
    letter-spacing: 1px;
}

@media (max-width: 767px) {
    .pricing-rates.starter-plan {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.watch-video a.watch {
    font-size: 14px;
    font-weight: 700;
}

.watch-video a .play-icon-circle {
    height: 36px;
    width: 36px;
    background-color: $primary;
    line-height: 36px;
}

.watch-video a .play-icon-circle.play-iconbar {
    font-size: 16px;
}

.watch-video a .play-icon-circle.play {
    top: 77%;
}

.bg-cta {
    padding: 150px 0;
}

.play-btn {
    height: 78px;
    font-size: 30px;
    line-height: 76px;
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.play-icon {
    position: absolute;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    right: 0;
    left: 0;
    text-align: center;
}

.play-icon i {
    height: 75px;
    -webkit-text-stroke: 2px $primary;
    -webkit-text-fill-color: transparent;
    width: 75px;
    font-size: 25px;
    line-height: 75px;
    display: inline-block;
}

.play-icon i:hover {
    -webkit-text-stroke: 2px $primary;
    -webkit-text-fill-color: $primary;
}

.mfp-iframe-holder .mfp-content {
    max-width: 1110px;
}

.blog {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.blog .content h4 {
    line-height: 1.2;
}

.blog .content .title {
    font-size: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.blog .content .title:hover {
    color: $primary !important;
}

.blog .content .post-meta .like,
.blog .content .post-meta .comments,
.blog .content .post-meta .readmore {
    font-size: 15px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.blog .content .post-meta .like:hover {
    color: $danger !important;
}

.blog .content .post-meta .comments:hover {
    color: $success !important;
}

.blog .content .post-meta .readmore:hover {
    color: $primary !important;
}

.blog .author,
.blog .teacher {
    position: absolute;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    right: 5%;
}

.blog .author {
    top: 5%;
    left: 5%;
}

.blog .teacher {
    bottom: 6%;
    left: 5%;
}

.blog .course-fee .fee {
    line-height: 50px;
}

.blog.blog-detail:hover {
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
}

.blog:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.blog:hover .overlay {
    opacity: 0.4;
}

.blog:hover .author,
.blog:hover .teacher,
.blog:hover .course-fee {
    opacity: 1;
}

.blog .blog-list-meta {
    position: absolute;
    bottom: 24px;
}

.sidebar .widget .widget-search form {
    position: relative;
}

.sidebar .widget .widget-title {
    font-size: 18px;
}

.sidebar .widget .blog-catagories li {
    padding-bottom: 10px;
}

.sidebar .widget .blog-catagories li:last-child {
    padding-bottom: 0;
}

.sidebar .widget .blog-catagories li a,
.sidebar .widget .blog-catagories li span {
    font-size: 15px;
}

.sidebar .widget .blog-catagories li a {
    color: #58595B;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sidebar .widget .blog-catagories li a:hover {
    color: $primary;
}

.sidebar .widget .post-recent {
    padding-bottom: 15px;
}

.sidebar .widget .post-recent:last-child {
    padding-bottom: 0;
}

.sidebar .widget .post-recent .post-recent-thumb {
    width: 25%;
}

.sidebar .widget .post-recent .post-recent-content a {
    display: block;
    color: #58595B;
    font-size: 15px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sidebar .widget .post-recent .post-recent-content a:hover {
    color: $primary;
}

.sidebar .widget .post-recent .post-recent-content span {
    font-size: 13px;
}

.sidebar .widget .tagcloud > a {
    background: #e9ecef;
    color: #58595B;
    display: inline-block;
    font-size: 9px;
    letter-spacing: 1px;
    margin: 5px 10px 5px 0;
    padding: 8px 12px 7px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-weight: 600;
}

.sidebar .widget .tagcloud > a:hover {
    background: $primary;
    color: #ffffff;
}

.page-title {
    font-size: 20px;
}

.media-list .media .media-heading:hover {
    color: $primary !important;
}

.blog .overlay,
.job-box .job-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.job-box {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.job-box .job-overlay {
    opacity: 0.6;
}

.job-box .head {
    position: absolute;
    top: 15px;
    right: 15px;
    left: 15px;
}

.job-box .position {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
}

.job-box .position a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.job-box .position a:hover {
    color: $primary !important;
}

.job-box .firm-logo {
    position: absolute;
    left: 0;
    right: 0;
    height: 60px;
    width: 60px;
    line-height: 60px;
    margin: 0 auto;
    top: -30px;
}

.job-box .company-name {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.job-box .company-name:hover {
    color: $primary !important;
}

.job-box:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

@media (min-width: 1024px) {
    .job-profile {
        top: -378px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .job-profile {
        top: -338px;
    }
}

@media (max-width: 767px) {
    .company-logo {
        float: none !important;
        text-align: left !important;
    }
}

.work-container.work-classic .work-image {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.work-container.work-classic .work-image:hover {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.work-container.work-classic .content .title,
.work-container.work-grid .content .title,
.work-container.work-modern .content .title {
    font-size: 18px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.work-container.work-classic .content .title:hover,
.work-container.work-grid .content .title:hover,
.work-container.work-modern .content .title:hover {
    color: $primary !important;
}

.work-container.work-classic .content .tag,
.work-container.work-grid .content .tag,
.work-container.work-modern .content .tag {
    font-size: 15px !important;
}

.work-container.work-modern img,
.work-container.work-modern .overlay-work,
.work-container.work-modern .content,
.work-container.work-modern .icons,
.work-container.work-modern .icons .work-icon {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.work-container.work-modern .overlay-work,
.work-container.work-modern .content,
.work-container.work-modern .icons {
    position: absolute;
}

.work-container.work-modern .overlay-work {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
}

.work-container.work-modern .content {
    z-index: 1;
    bottom: 5%;
    left: 5%;
}

.work-container.work-modern .content,
.work-container.work-modern .client {
    opacity: 0;
}

.work-container.work-modern .read_more {
    height: 30px;
    width: 30px;
    line-height: 30px;
}

.work-container.work-modern .icons {
    right: 0;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0;
}

.work-container.work-modern .icons .work-icon {
    height: 45px;
    width: 45px;
    line-height: 45px;
}

.work-container.work-modern .icons .work-icon:hover {
    background: $primary !important;
    color: #ffffff !important;
}

.work-container.work-modern:hover img {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
}

.work-container.work-modern:hover .overlay-work {
    opacity: 0.65;
}

.work-container.work-modern:hover .icons {
    opacity: 1;
}

.work-container.work-modern:hover .personal-port,
.work-container.work-modern:hover .content,
.work-container.work-modern:hover .client {
    opacity: 1;
}

.work-container.work-grid {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.work-container.work-grid img,
.work-container.work-grid .content,
.work-container.work-grid .content .title {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.work-container.work-grid .content {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
    overflow: hidden;
}

.work-container.work-grid:hover {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.work-container.work-grid:hover img {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
}

.work-container.work-grid:hover .content {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.work-details ul li b {
    max-width: 90px;
    width: 100%;
    display: inline-block;
}

.courses-desc img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.courses-desc:hover img {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
}

.courses-desc .overlay-work,
.popular-tour .overlay-work {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.courses-desc:hover .overlay-work,
.popular-tour:hover .overlay-work {
    opacity: 0.6;
}

.event-schedule {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.event-schedule .date .day {
    font-size: 18px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: rgba(47, 85, 212, 0.1);
    line-height: 48px;
    border: 2px solid #ffffff;
    -webkit-box-shadow: 0px 0px 2px 0.25px rgba(47, 85, 212, 0.5);
    box-shadow: 0px 0px 2px 0.25px rgba(47, 85, 212, 0.5);
}

.event-schedule .date .month {
    font-size: 13px;
}

.event-schedule .content .title {
    font-size: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.event-schedule .content .title:hover {
    color: $primary !important;
}

.event-schedule .content .location-time {
    font-size: 14px;
}

.event-schedule:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    border-color: $primary !important;
    background: #f8f9fc !important;
}

.courses-desc {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.courses-desc .content .title {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.courses-desc .content .title:hover {
    color: $primary !important;
}

.courses-desc .collection i {
    -webkit-text-stroke: 2px $danger;
    -webkit-text-fill-color: transparent;
}

.courses-desc .collection i:active {
    -webkit-text-stroke: 0px $danger;
    -webkit-text-fill-color: $danger;
}

.courses-desc:hover {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.courses-desc:hover .collection,
.courses-desc:hover .preview {
    opacity: 1;
}

.personal-port {
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.container-filter li a {
    font-size: 13px;
    padding: 0px 15px;
    margin: 5px 3px;
    font-weight: 700;
    letter-spacing: 0.8px;
    cursor: pointer;
    line-height: 34px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.container-filter li a.active,
.container-filter li a:hover {
    color: $primary !important;
    border-color: $primary !important;
}

.spacing {
    padding-left: 7px;
    padding-right: 7px;
}

.shop-list .shop-image .overlay-work {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.shop-list .shop-image:hover .overlay-work,
.shop-list .shop-image:hover .shop-icons {
    opacity: 1;
}

.shop-list .shop-image:hover .shop-icons {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
}

.shop-list .content .product-name {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.shop-list .content .product-name:hover {
    color: $primary !important;
}

.popular-tour .content {
    position: absolute;
    bottom: 3%;
    left: 5%;
}

.main-icon {
    border: 2px solid #e9ecef;
    height: 40px;
    width: 40px;
    line-height: 0;
    text-align: center;
}

.main-icon .fea {
    height: 20px;
}

.timeline-page:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 95%;
    margin: auto;
    width: 2px;
    background-color: #e9ecef !important;
}

.timeline-page .timeline-item .date-label-left:after {
    content: "";
    position: absolute;
    top: 12px;
    width: 12px;
    height: 12px;
    z-index: 1;
    background-color: $primary;
    border-radius: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -37px !important;
    right: auto;
}

.timeline-page .timeline-item .event {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

@media (min-width: 768px) {
    .main-icon {
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .timeline-page {
        margin-left: 18px;
    }

    .timeline-page:after {
        margin: 0;
    }

    .timeline-page .timeline-item .duration {
        float: left !important;
        margin: 0 0 30px 15px !important;
        text-align: left !important;
    }

    .timeline-page .timeline-item .duration:after {
        left: -21px !important;
    }

    .timeline-page .timeline-item .duration .event {
        text-align: left !important;
        margin-left: 15px;
    }

    .timeline-page .timeline-item .event-description-left {
        text-align: left !important;
        margin: 0 0 0 15px;
    }
}

.team img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.team .name:hover {
    color: $primary !important;
}

.team .team-icon {
    position: absolute;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    right: 0;
    opacity: 0;
    left: 0;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.team:hover img {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    opacity: 0.2;
}

.team:hover .overlay-team {
    opacity: 0.6;
}

.team:hover .team-icon {
    opacity: 1;
}

.login-page {
    font-size: 15px;
}

.login-page .forgot-pass {
    z-index: 99;
    position: relative;
    font-size: 13px;
}

.cover-user .container-fluid {
    max-width: 100%;
}

.cover-user .container-fluid .padding-less.img {
    background: scroll center no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
}

.cover-user .cover-user-img {
    height: 100vh;
}

.bg-profile {
    padding: 100px 0;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-profile .public-profile {
    top: 175px;
}

.accounts {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.accounts .pro-icons,
.accounts .title {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.accounts:hover,
.accounts.active {
    background-color: $primary;
}

.accounts:hover .pro-icons,
.accounts.active .pro-icons {
    color: rgba(255, 255, 255, 0.5) !important;
}

.accounts:hover .title,
.accounts.active .title {
    color: #ffffff !important;
}

@media (min-width: 1025px) {
    .cover-user .cover-user-img {
        padding: 0px 20%;
    }
}

@media (max-width: 768px) {
    .cover-user .padding-less {
        position: relative !important;
        height: 400px !important;
    }

    .cover-user .cover-user-content {
        text-align: left !important;
    }

    .cover-user .cover-user-img {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: start !important;
        height: auto !important;
    }

    .cover-user .cover-my-30 {
        margin: 30px 0;
    }
}

.coming-soon,
.maintenance,
.error-page {
    font-size: 40px;
    letter-spacing: 1px;
}

#countdown .count-down,
#eventdown .count-down {
    display: inline-block;
    margin: 30px 10px;
    color: #ffffff;
    border-radius: 50%;
    text-align: center;
    border: 4px solid #dee2e6;
    height: 130px;
    width: 130px;
}

#countdown .count-down .count-number,
#eventdown .count-down .count-number {
    font-size: 40px;
    line-height: 95px;
}

#countdown .count-down .count-head,
#eventdown .count-down .count-head {
    display: block;
    position: relative;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
}

#eventdown .count-down {
    margin: 10px !important;
    border: 0;
    height: 20px;
    width: 80px;
}

#eventdown .count-down .count-number {
    line-height: 80px;
}

#eventdown .count-down .count-head {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
}

#clock {
    color: #ffffff;
}

#clock p {
    width: 80px;
}

#clock p span {
    font-size: 40px;
}

#clock p span.indicator {
    font-size: 18px;
}

@media (min-width: 200px) and (max-width: 767px) {

    .coming-soon,
    .maintenance,
    .error-page {
        font-size: 32px;
    }

    #eventdown .count-down {
        width: 60px;
    }

    #eventdown .count-down .count-number {
        font-size: 30px;
    }

    #eventdown .count-down .count-head {
        font-size: 10px;
    }
}

.contact-detail .content .title {
    font-size: 20px;
}

.form-group .icons {
    position: absolute;
    top: 39px;
    left: 18px;
    right: 18px;
}

.map {
    line-height: 0;
}

.map iframe {
    width: 100%;
    height: 400px;
}

.error {
    margin: 8px 0px;
    display: none;
    color: $danger;
}

#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px solid $danger;
    color: $danger;
    border-radius: 6px;
    font-size: 14px;
}

.contact-loader {
    display: none;
}

#success_page {
    text-align: center;
    margin-bottom: 50px;
}

#success_page h3 {
    color: $success;
    font-size: 22px;
}

@media (min-width: 768px) {
    .map.map-height-two iframe {
        height: 551px;
    }
}

.footer {
    background: #202942;
    padding: 60px 0;
    position: relative;
    color: #adb5bd;
}

.footer .logo-footer {
    font-size: 22px;
}

.footer .footer-head {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500;
}

.footer .foot-subscribe .form-control {
    background-color: #27314f;
    border: 1px solid #27314f;
    color: #f8f9fc;
}

.footer .foot-subscribe .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.footer .foot-subscribe.foot-white .form-control {
    color: #adb5bd;
}

.footer .foot-subscribe input::-webkit-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input:-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
    color: #adb5bd;
}

.footer .text-foot {
    color: #adb5bd;
}

.footer .footer-list {
    margin-bottom: 0;
}

.footer .footer-list li {
    margin-bottom: 10px;
}

.footer .footer-list li a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.footer .footer-list li a:hover {
    color: #e6e8ea;
}

.footer .footer-list li:last-child {
    margin-bottom: 0;
}

.footer.footer-bar {
    padding: 30px 0;
    border-top: 1px solid #656870;
}

#style-switcher {
    background: none repeat scroll 0 0 #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    left: -189px;
    position: fixed;
    top: 17%;
    width: 189px;
    z-index: 9999;
    padding: 10px 5px;
}

#style-switcher .title {
    font-size: 16px;
}

#style-switcher .bottom {
    background: none repeat scroll 0 0 #ffffff;
}

#style-switcher .bottom a.settings {
    background: none repeat scroll 0 0 #ffffff;
    height: 41px;
    position: absolute;
    right: -41px;
    top: 0px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0;
}

#style-switcher ul.pattern {
    list-style: none outside none;
    overflow: hidden;
    padding: 0;
    border-radius: 0px;
}

#style-switcher ul.pattern li.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 0;
}

#style-switcher ul.pattern li a {
    cursor: pointer;
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin: 3px;
}

#style-switcher ul.pattern .default {
    background-color: $primary;
}

#style-switcher ul.pattern .green {
    background-color: #6dc77a;
}

#style-switcher ul.pattern .red {
    background-color: #ff5b69;
}

#style-switcher ul.pattern .purple {
    background-color: #7952B3;
}

#style-switcher ul.pattern .skyblue {
    background-color: #32c2ec;
}

#style-switcher ul.pattern .skobleoff {
    background-color: #0f7173;
}

#style-switcher ul.pattern .cyan {
    background-color: #00c9a7;
}

#style-switcher ul.pattern .slateblue {
    background-color: #766df4;
}

#style-switcher .t-dark,
#style-switcher .t-rtl-light {
    display: inline-block;
}

#style-switcher .t-ltr-light,
#style-switcher .t-light,
#style-switcher .t-rtl-dark,
#style-switcher .t-ltr-dark {
    display: none;
}

@media only screen and (max-width: 425px) {
    #style-switcher {
        display: none;
    }
}

html {
    direction: rtl;
}

#style-switcher .t-rtl-light {
    display: none;
}

#style-switcher .t-ltr-light {
    display: inline-block;
}

.text-left {
    text-align: right !important;
}

.text-right {
    text-align: left !important;
}

.float-right {
    float: left !important;
}

.float-left {
    float: right !important;
}

dd {
    margin-right: 0 !important;
}

.list-unstyled {
    padding-right: 0 !important;
    padding-left: auto !important;
}

.list-inline {
    padding-right: 0;
    list-style: none;
}

.modal-open {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ml-auto {
    margin-right: auto;
    margin-left: 0 !important;
}

.mr-auto {
    margin-left: auto;
    margin-right: 0 !important;
}

.mr-1 {
    margin-left: 4px !important;
    margin-right: auto !important;
}

.mr-2 {
    margin-left: 8px !important;
    margin-right: auto !important;
}

.mr-3 {
    margin-left: 16px !important;
    margin-right: auto !important;
}

.mr-4 {
    margin-left: 24px !important;
    margin-right: auto !important;
}

.mr-5 {
    margin-left: 48px !important;
    margin-right: auto !important;
}

.ml-1 {
    margin-right: 4px !important;
    margin-left: auto !important;
}

.ml-2 {
    margin-right: 8px !important;
    margin-left: auto !important;
}

.ml-3 {
    margin-right: 16px !important;
    margin-left: auto !important;
}

.ml-4 {
    margin-right: 24px !important;
    margin-left: auto !important;
}

.ml-5 {
    margin-right: 48px !important;
    margin-left: auto !important;
}

.pr-1 {
    padding-left: 4px !important;
    padding-right: auto !important;
}

.pr-2 {
    padding-left: 8px !important;
    padding-right: auto !important;
}

.pr-3 {
    padding-left: 16px !important;
    padding-right: auto !important;
}

.pr-4 {
    padding-left: 24px !important;
    padding-right: auto !important;
}

.pr-5 {
    padding-left: 48px !important;
    padding-right: auto !important;
}

.pl-1 {
    padding-right: 4px !important;
    padding-left: auto !important;
}

.pl-2 {
    padding-right: 8px !important;
    padding-left: auto !important;
}

.pl-3 {
    padding-right: 16px !important;
    padding-left: auto !important;
}

.pl-4 {
    padding-right: 24px !important;
    padding-left: auto !important;
}

.pl-5 {
    padding-right: 48px !important;
    padding-left: auto !important;
}

@media (min-width: 992px) {
    .mr-lg-2 {
        margin-left: 8px !important;
        margin-right: auto !important;
    }

    .mr-lg-3 {
        margin-left: 16px !important;
        margin-right: auto !important;
    }

    .mr-lg-4 {
        margin-left: 24px !important;
        margin-right: auto !important;
    }

    .mr-lg-5 {
        margin-left: 48px !important;
        margin-right: auto !important;
    }

    .ml-lg-3 {
        margin-right: 16px !important;
        margin-left: auto !important;
    }

    .ml-lg-4 {
        margin-right: 24px !important;
        margin-left: auto !important;
    }

    .ml-lg-5 {
        margin-right: 48px !important;
        margin-left: auto !important;
    }

    .pl-lg-3 {
        padding-right: 16px !important;
        padding-left: auto !important;
    }

    .text-lg-left {
        text-align: right !important;
    }

    .offset-lg-1 {
        margin-left: auto;
        margin-right: 8.333333%;
    }

    .offset-lg-4 {
        margin-right: 33.333333%;
    }

    .offset-lg-6 {
        margin-right: 50%;
    }
}

@media (min-width: 768px) {
    .ml-md-4 {
        margin-right: 24px !important;
        margin-left: 0 !important;
    }

    .mr-md-4 {
        margin-left: 24px !important;
        margin-right: 0 !important;
    }

    .pr-md-3 {
        padding-left: 16px !important;
        padding-right: 0 !important;
    }

    .pl-md-3 {
        padding-right: 16px !important;
        padding-left: 0 !important;
    }

    .pl-lg-5 {
        padding-right: 48px !important;
        padding-left: 0 !important;
    }

    .text-md-left {
        text-align: right !important;
    }

    .text-md-right {
        text-align: left !important;
    }
}

.btn-group .dropdown-menu.show {
    right: 0;
    left: auto;
}

.btn-group .dropdown-item {
    text-align: right !important;
}

.alert.alert-dismissible {
    padding: 8px 15px 8px 30px;
    padding-right: 30px;
}

.breadcrumb .breadcrumb-item {
    margin-left: 0px !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-left: 0;
    display: inline-block;
}

.breadcrumb .breadcrumb-item:before {
    content: "\F0141";
    color: #58595B;
    padding-left: 5px;
}

.breadcrumb .breadcrumb-item:after {
    display: none;
    content: "\F0142" !important;
    font-size: 14px;
    color: #58595B;
    padding-left: 5px;
}

.breadcrumb .breadcrumb-item:first-child:before {
    display: none;
}

.pagination .page-item:last-child .page-link {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pagination .page-item:first-child .page-link {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.faq-container .question {
    font-size: 20px;
}

.faq-content .card .faq .card-header {
    padding-left: 40px !important;
    padding-right: auto !important;
}

.faq-content .card a.faq[data-toggle=collapse]:before {
    left: 20px;
    right: auto;
    content: '\F0143';
    display: block;
    font-size: 18px;
    color: #58595B;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.nav {
    padding-right: 0;
    padding-right: auto;
}

.progress-box .progress .progress-value {
    left: -15px;
    right: auto;
    position: absolute;
    top: -25px;
    font-size: 13px;
}

.blockquote {
    border-right: 3px solid #dee2e6;
    border-left: 0;
    border-radius: 6px;
    font-size: 16px;
}

.subcribe-form input {
    padding-left: 160px;
    padding-right: 30px;
    width: 100%;
    color: #58595B !important;
    border: none;
    outline: none !important;
    background-color: rgba(255, 255, 255, 0.8);
}

.subcribe-form button {
    left: 6px;
    right: auto;
    position: absolute;
    top: 6px;
    outline: none !important;
}

.input-group > .input-group-append > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.input-group > .form-control:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.back-to-top {
    left: 30px;
    right: auto;
    z-index: 99;
    position: fixed;
    bottom: 55px;
    display: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.back-to-home {
    left: 2%;
    right: auto;
    position: absolute;
    top: 4%;
    z-index: 1;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
    right: 0 !important;
    left: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $primary;
    opacity: 0.6;
    position: absolute;
    top: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.blog .course-fee {
    left: 5%;
    right: auto;
    bottom: -5%;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sidebar .widget .widget-search input[type="text"],
.sidebar .widget .searchform input[type="text"] {
    padding-left: 45px;
    padding-right: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 45px;
    font-size: 14px;
    display: block;
    width: 100%;
    outline: none !important;
}

.sidebar .widget .widget-search input[type="submit"],
.sidebar .widget .searchform input[type="submit"] {
    left: 10px;
    right: auto;
    position: absolute;
    top: 5px;
    opacity: 0;
    width: 40px;
    height: 40px;
}

.sidebar .widget .widget-search .searchform:after {
    left: 16px;
    right: auto;
    content: "\F0349";
    position: absolute;
    top: 15px;
    font-size: 20px;
    line-height: 20px;
    pointer-events: none;
}

.sidebar .widget .catagories span {
    float: left !important;
}

.sidebar .widget .post-recent .post-recent-content {
    padding-right: 10px;
    padding-left: auto;
    width: 75%;
}

.media-list .sub-comment {
    border-right: 2px dashed #dee2e6;
    border-left: 0;
}

.form-group {
    text-align: right;
    margin-bottom: 20px;
}


.custom-control {
    padding-right: 1.5rem;
    padding-left: 0;
}

.custom-control.custom-switch {
    padding-right: 2.25rem !important;
}

.custom-control-label:before,
.custom-control-label:after {
    right: -1.5rem;
    top: 2px;
}

.form-check-inline {
    margin-right: 0;
    padding-left: .75rem;
}

.custom-control-inline {
    margin-left: 1rem;
    margin-right: 0;
}

.custom-switch .custom-control-label:before {
    right: -2.35rem;
}

.custom-switch .custom-control-label:after {
    left: calc(-2.25rem + 2px);
    top: 4px;
}

.custom-select {
    padding: .375rem .75rem .375rem 1.75rem !important;
    background-position: left 0.75rem center !important;
}

.modal-header .close {
    margin: -1rem auto -1rem -1rem !important;
}

.modal-footer > :not(:last-child) {
    margin-left: .25rem;
    margin-right: 0;
}

.modal-footer > :not(:first-child) {
    margin-left: 0;
    margin-right: .25rem;
}


.key-feature .icon,
.key-feature .icons {
    margin-right: 0 !important;
}

.bg-animation-left:after {
    right: 0px;
    -webkit-clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 70%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
}

.bg-animation-left.crypto-home:after {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 70% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 70% 100%);
    background: $primary;
    width: 80%;
}

.agency-wrapper:before {
    left: 54rem;
    right: auto;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    content: "";
    position: absolute;
    bottom: 22rem;
    width: 90rem;
    height: 35rem;
    border-radius: 17rem;
    background-color: #f8f9fc;
    z-index: -1;
}

.classic-app-image .bg-app-shape:after {
    right: -8rem;
    left: auto;
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    bottom: 3rem;
    width: 55rem;
    height: 35rem;
    border-radius: 20rem;
    opacity: 0.9;
    content: " ";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
    background-color: $primary;
}

.classic-app-image .app-images {
    left: -20px;
    right: auto;
    position: absolute;
    bottom: 60px;
}

.app-feature-shape-right:after {
    left: -5rem;
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
    bottom: 0rem;
    width: 30rem;
    height: 40rem;
    border-radius: 10rem;
    background: rgba(47, 85, 212, 0.1);
    -webkit-box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    content: "";
    position: absolute;
    z-index: -1;
}

.classic-saas-image .bg-saas-shape:after {
    right: 0;
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg);
    bottom: 3rem;
    left: 0;
    width: 75rem;
    height: 55rem;
    border-radius: 20rem;
    opacity: 0.9;
    content: " ";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
    background-color: $primary;
}

.saas-feature-shape-left:after {
    right: -10rem;
    left: auto;
    bottom: -3rem;
    width: 35rem;
    height: 35rem;
    border-radius: 15rem;
    -webkit-transform: rotate(145deg);
    transform: rotate(145deg);
    background: rgba(47, 85, 212, 0.1);
    -webkit-box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    content: "";
    position: absolute;
    z-index: -1;
}

.saas-feature-shape-right:after {
    left: -10rem;
    right: auto;
    bottom: -3rem;
    width: 35rem;
    height: 35rem;
    border-radius: 15rem;
    -webkit-transform: rotate(145deg);
    transform: rotate(145deg);
    background: rgba(47, 85, 212, 0.1);
    -webkit-box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
    content: "";
    position: absolute;
    z-index: -1;
}

.shape-before .shape-img {
    right: -35px !important;
    left: auto;
    position: absolute;
    top: -50px;
    z-index: -1;
    height: auto;
    overflow: hidden;
}


.pricing-rates.best-plan .lable {
    left: -65px;
    right: auto;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 200px;
    position: absolute;
    top: 15px;
    z-index: 1;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded,
.slick-slider,
.swiper-slider-hero {
    direction: ltr;
}

.customer-testi.media img {
    margin-right: 16px !important;
    margin-left: auto !important;
}

.customer-testi.media .content {
    text-align: left !important;
}


.work-container .content {
    right: 5%;
}

.work-container.work-modern .client,
.work-container.work-modern .read_more {
    left: 5%;
    right: auto;
    z-index: 1;
    top: 5%;
    position: absolute;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.courses-desc .collection {
    top: 15px;
    right: 15px;
    left: 15px;
    position: absolute;
    opacity: 0;
    z-index: 1;
}

.courses-desc .preview {
    bottom: 15px;
    left: 15px;
    right: 15px;
    position: absolute;
    opacity: 0;
    z-index: 1;
}

.shop-list .shop-image .shop-icons {
    right: auto;
    left: 15px;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    top: 15px;
    z-index: 2;
    position: absolute;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.timeline-page .timeline-item .date-label-left {
    float: left !important;
    margin-right: 0;
    margin-left: 15px !important;
}

.timeline-page .timeline-item .duration-right {
    float: right !important;
    margin-right: 15px !important;
    margin-left: auto;
}

.timeline-page .timeline-item .duration-right:after {
    right: -37px !important;
    left: auto;
    content: "";
    position: absolute;
    top: 12px;
    width: 12px;
    height: 12px;
    z-index: 1;
    background-color: $primary;
    border-radius: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.timeline-page .timeline-item .event.event-description-right {
    text-align: right !important;
    margin-right: 15px !important;
    margin-left: 0;
}

.timeline-page .timeline-item .event.event-description-left {
    text-align: left;
    margin-left: 15px !important;
    margin-right: 0;
}

.social-feature-left:before {
    left: -200px;
    right: auto;
}

.social-feature-right:before {
    right: -200px;
    left: auto;
}

@media (min-width: 769px) {
    .bg-studio:after {
        left: 0px;
    }
}

@media (max-width: 768px) {
    .agency-wrapper:before {
        left: 12rem;
    }

    .app-feature-shape-right:after {
        left: -10rem;
    }

    .app-feature-shape-left:after {
        right: -10rem;
    }
}

@media (max-width: 767px) {
    .agency-wrapper:before {
        left: 0;
    }

    .company-logo {
        float: none !important;
        text-align: right !important;
    }

    .timeline-page {
        margin-right: 18px !important;
        margin-left: 0;
    }

    .timeline-page .timeline-item .duration {
        float: right !important;
        margin-left: 0 !important;
        margin-right: 15px !important;
        text-align: right !important;
    }

    .timeline-page .timeline-item .duration:after {
        right: -21px !important;
        left: auto;
    }

    .timeline-page .timeline-item .duration .event {
        text-align: right !important;
        margin-right: 15px !important;
        margin-left: auto;
    }

    .timeline-page .timeline-item .event-description-left {
        text-align: right !important;
        margin-left: 0 !important;
        margin-right: 15px !important;
    }
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: right !important;
    }

    .text-sm-right {
        text-align: left !important;
    }
}

@media (max-width: 575px) {
    .social-feature-left:before {
        left: 60px;
        right: auto;
    }

    .social-feature-right:before {
        right: 60px;
        left: auto;
    }
}

#topnav .logo {
    float: right;
    color: #58595B !important;
}

#topnav .has-submenu .submenu .submenu-arrow {
    border-width: 2px 0px 0px 2px;
    left: 20px;
    right: auto;
    border: solid #58595B;
    border-radius: 0.5px;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    top: 14px;
}

#topnav .buy-button {
    float: left;
    padding: 0 0 6px;
    line-height: 68px;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
    left: -1px;
    right: auto;
    border: solid #58595B;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    top: 30px;
}

.has-submenu .menu-arrow {
    left: -1px;
    right: auto;
}

@media (min-width: 992px) {
    #topnav .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu::before {
        margin-right: -15px;
        margin-left: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    #topnav .navigation-menu > li .submenu {
        right: 0;
        left: auto;
    }

    #topnav .navigation-menu > li .submenu:before {
        right: 11px;
        left: auto;
    }

    #topnav .navigation-menu > li .submenu li ul {
        padding-right: 0;
        padding-left: auto;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
        right: 100%;
        margin-right: 10px;
    }

    #topnav .navigation-menu > li .submenu > li .submenu {
        right: 106%;
        left: auto;
        margin-right: 10px;
    }

    #topnav .navigation-menu > li.last-elements .submenu {
        right: auto;
        left: 0;
    }

    #topnav .navigation-menu > li.last-elements .submenu:before {
        right: auto;
        left: 35px;
    }

    #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
        right: auto;
        left: 100%;
        margin-right: 0;
        margin-left: 10px;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu {
        left: auto !important;
        right: 0 !important;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
        left: auto !important;
        right: 11px !important;
    }

    #topnav .buy-button {
        padding-right: 15px !important;
        margin-right: 15px !important;
        padding-left: auto;
        margin-left: auto;
    }
}

@media (max-width: 991px) {
    #topnav .navigation-menu > li .submenu {
        padding-right: 20px;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li > ul {
        padding-right: 0;
        padding-left: auto;
    }

    #topnav .has-submenu .submenu .submenu-arrow {
        left: 20px;
        right: auto;
    }

    #navigation {
        right: 0;
        left: auto;
    }
}

@media (max-width: 768px) {
    #topnav .navigation-menu .has-submenu .menu-arrow {
        left: 16px;
        right: auto;
    }

    .has-submenu .menu-arrow {
        left: 16px;
        right: auto;
    }

    #topnav .navigation-menu .has-submenu .submenu .submenu-arrow {
        border-width: 0px 2px 2px 0px;
    }
}

@media (max-width: 375px) {
    #topnav .navbar-toggle .lines {
        margin-left: 0 !important;
        margin-right: auto;
    }
}

/* Icons */
.mdi-arrow-right::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    content: "\F004D"
}

.mdi-arrow-left::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    content: "\F0054";
}

.mdi-chevron-right::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

/* Custom Css */
.dropdownItem {
    text-align: right;
}

.mdi-apple-keyboard-control::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

/*# sourceMappingURL=style-rtl.css.map */
.carousel-indicators {
    direction: ltr;
}

.accordion-button:after {
    margin-right: auto;
    margin-left: 0 !important;
}
