

/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */



@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

@import 'variable';

:root {
    --primaryColor: $primary;
    --infoColor: $info;
    --successColor: $success;
    --dangerColor: $danger;
    --warningColor: $warning;
    --blackColor: #2f3d4a;
    --paragraphColor: #58595b;
    --fontSize: 15px;
    --transition: .5s;
}

@import 'app';
@import 'pages';
@import 'sidebar';
@import 'widgets';
@import 'grid';
@import 'responsive';
@import 'spinners.scss';
@import 'animate.css';
@import 'theme-settings.scss';
// color css
@import 'colors/blue-dark';
@import 'colors/default-dark';
@import 'colors/red-dark';
@import 'colors/green-dark';
@import 'colors/purple-dark';
@import 'colors/megna-dark';
@import 'colors/megna';
@import 'colors/blue';
@import 'colors/red';
@import 'colors/green';
@import 'colors/purple';
@import 'colors/default';
// dark theme
@import 'dark';
// RTL Demo
@import 'rtl/rtl';

@import 'overrides.scss';