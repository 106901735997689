/* Padding margin for rtl */

$vertical-property-map: (m-r-: margin-left, m-l-: margin-right, p-r-: padding-left, p-l-: padding-right);
$sizes-list: 5 10 15 20 25 30 40;
@each $size in $sizes-list {
    $val: $size + 0px;
    @each $keyword,
    $property in $vertical-property-map {
        html body .rtl-theme .#{$keyword}#{$size} {
            #{$property}: $val;
        }
    }
}

/* Discarding margin padding of ltr */

$ltr-property-map: (m-r-: margin-right, m-l-: margin-left, p-r-: padding-right, p-l-: padding-left);
$sizes-list: 5 10 15 20 25 30 40;
@each $size in $sizes-list {
    @each $keyword,
    $property in $ltr-property-map {
        html body .rtl-theme .#{$keyword}#{$size} {
            #{$property}: 0;
        }
    }
}

/* Zero margin padding for rtl */

$zero-property-map: (m-r: margin-left, m-l: margin-right, p-r: padding-left, p-l: padding-right);
@each $keyword,
$property in $zero-property-map {
    html body .rtl-theme .#{$keyword}-0 {
        #{$property}: 0;
    }
}
