/* You can add global styles to this file, and also import other style files */

@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~ngx-toastr/toastr';
@import 'assets/scss/rtl/style-rtl.scss';

@font-face {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/Almarai-Regular.ttf);
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}

* {
    font-family: "Almarai", sans-serif;
    text-align: right;
    letter-spacing: 0 !important;
}

body,
html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
