/*
Template Name: homains - Responsive Bootstrap 4 Admin Dashboard
Author: Themes brand
Version: 1.0.0
Website: https://www.homains.eu/

File: Icons Css File
*/

// Plugins
@import 'icons/font-awesome/css/font-awesome.css';
