/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/


/*******************
Main sidebar
******************/

.left-sidebar {
    position: absolute;
    width: 240px;
    height: 100vh;
    top: 0px;
    z-index: 20;
    padding-top: 70px;
    background: $sidebar;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.fix-sidebar .left-sidebar {
    position: fixed;
}


/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;
  .setpos {
    top: -47px;
    right: -3px;
  }
  .profile-img {
    width: 70px;
    margin: 0 auto;
    padding: 10px 0 5px 0;
    border-radius: 100%;
    img {
        width: 100%;
        padding: 5px;
        border: 1px solid $border;
        border-radius: 100%;
    }
    h1 {
        width: 45px;
        padding: 3px;
        border: 1px solid rgba(120, 130, 140, 0.13);
        border-radius: 100%;
        height: 45px;
        text-align: center;
    }
  }
  .profile-text {
    padding: 5px 0px;
    position: relative;
    text-align: center;
    >a {
      color: $muted;
      padding: 0 5px;
      &:hover {
        color: $themecolor;
      }
      &:after {
        display: none;
      }
    }
  }
  .dropdown-menu {
    left: 0px!important;
    right: 0px;
    top: 62px!important;
    width: 180px;
    margin: 0 auto;
  }
}


/*******************
sidebar navigation
******************/

.scroll-sidebar {
    height: calc(100vh - 70px);
    position: relative;
    &.ps .ps__rail-y {
        left: 2px;
        right: auto;
        background: none;
        width: 6px;
        /* If using `left`, there shouldn't be a `right`. */
    }
}


.collapse:not(.show) {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
}
  
.collapse.in {
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    box-shadow: 0px 5px 20px #dee2e6 !important;
    opacity: 1;
    height: 100%;
    z-index: 1000;
}

.nav-small-cap {
    font-size: 12px;
    margin-bottom: 0px;
    padding: 14px 14px 14px 20px;
    color: $dark;
    font-weight: 500;
}

.sidebar-nav {
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style: none;
            a {
                color: $sidebar-text;
                padding: 8px 35px 8px 15px;
                display: block;
                font-size: 14px;
                font-weight: 400;
                &.active,
                &:hover {
                    color: $themecolor;
                    i {
                        color: $themecolor;
                    }
                }
                &.active {
                    font-weight: 500;
                    color: $dark;
                }
            }
            ul {
                padding-left: 36px;
                
                ul {
                    padding-left: 15px;
                }
            }
            &.nav-devider {
                height: 1px;
                background: $border;
                display: block;
                margin: 15px 0;
            }
        }
    }
    > ul > li > a {
        i {
            width: 31px;
            font-size: 24px;
            display: inline-block;
            vertical-align: middle;
            color: $sidebar-icons;
        }
        .label {
            float: right;
            margin-top: 6px;
        }
        &.active {
            font-weight: 400;
            background: #242933;
            color: $themecolor-alt;
        }
    }
    > ul > li {
        margin-bottom: 5px;
        &.active > a {
            color: $themecolor;
            font-weight: 500;
            i {
                color: $themecolor;
            }
        }
    }
    .waves-effect {
        transition: none;
        -webkit-transition: none;
        -o-transition: none;
    }
}

.sidebar-nav .has-arrow {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: $sidebar-text;
        right: 1em;
        -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
        -o-transform: rotate(135deg) translate(0, -50%);
        transform: rotate(135deg) translate(0, -50%);
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        top: 23px;
        -webkit-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }
}

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    top: 45%;
    width: 7px;
    transform: rotate(-135deg) translate(0, -50%);
}

.mini-sidebar .subItems {
    display: none !important;
}

.collapsedSubItems {
    display: none !important;
}

.mini-sidebar .collapsedSubItems {
    display: initial !important;
    position: fixed;
    height: auto;
    margin-right: 70px !important;
    margin-top: -40px !important;
}
